// eslint-disable-next-line no-unused-vars, object-curly-newline
import { computed, getCurrentInstance, readonly, ref, toRaw, watch } from '@vue/composition-api'

export default function useSaleOrderList() {
  const vm = getCurrentInstance().proxy

  const saleOrderListTable = ref([])

  const tableColumns = [
    { text: 'NUMBER', value: 'number' },
    { text: 'CUSTOMER', value: 'customerName' },

    // { text: 'SALESPERSON', value: 'salesperson' },
    { text: 'TOTAL', value: 'totalAmountIncludingTax' },
    { text: 'STATUS', value: 'status' },
    { text: 'ORDER DATE', value: 'orderDate' },

    // { text: 'REQUESTED DELIVERY DATE', value: 'requestedDeliveryDate' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const saleOrders = computed({
    get: () => vm.$store.getters['saleOrders/items'],
  })
  const loading = computed({
    get: () => vm.$store.getters['saleOrders/loading'],
  })
  const meta = computed({
    get: () => vm.$store.getters['saleOrders/meta'],
  })
  const filters = computed({
    get: () => vm.$store.getters['saleOrders/filters'],
  })
  const options = computed({
    get: () => vm.$store.getters['saleOrders/options'],
    set: val => vm.$store.dispatch('saleOrders/setOptions', { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters['saleOrders/search'],
    set: val => vm.$store.dispatch('saleOrders/setSearch', val),
  })
  const totalSaleOrderListTable = computed({
    get: () => vm.$store.getters['saleOrders/meta'].total,
  })

  const fetchItems = () => vm.$store.dispatch('saleOrders/fetchItems')
  const setOptions = val => vm.$store.dispatch('saleOrders/setOptions', val)
  const setFilters = val => vm.$store.dispatch('saleOrders/setFilters', val)

  const init = () => {
    fetchItems()
  }

  const destroy = () => {}

  return {
    saleOrderListTable,
    tableColumns,

    totalSaleOrderListTable,
    loading,
    fetchItems,

    saleOrders,
    meta,

    options,
    filters,
    search,

    setOptions,
    setFilters,

    init,
    destroy,
  }
}
